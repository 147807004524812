import React, { useState, useEffect } from 'react';
import './units.scss';
import { getPlayerUnits, selectUnit } from '../../http';
import Back from '../Back';
import Footer from '../Footer/Footer';
import arrow from './arrow.svg';
import done from './done.svg';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
function Units() {
  const [units, setUnits] = useState([]);
  const [show, setShow] = useState(false);
  const [currentUnit, setCurrentUnit] = useState(null); 
  const { setUnit } = useAppData();
  const navigate = useNavigate()

  const getAllUnitsData = async () => {
    try {
      const data = await getPlayerUnits();
      setUnits(data.units);
      console.log(data);
    } catch (error) {
      console.error('Failed to fetch skins', error);
    }
  };

  const handleSelect = async (id, icon, unitData) => {
    await selectUnit(id);
    setUnit(icon);
    setCurrentUnit(unitData); 
    await getAllUnitsData();
    setShow(true);
  };

  useEffect(() => {
    getAllUnitsData();
  }, []);

  if (!units) {
    return <div>Loading...</div>;
  }

  return (
    <div className='units'>
      <Back />
      <div className='title_units'>Choose your Unit</div>
      <div className='units_scroll'>
        {units.length > 0 ? (
          units.map((unit, index) => (
            <div
              className={unit.is_selected ? 'unit selected' : 'unit'}
              key={unit.skin_id}
              onClick={
                unit.is_selected
                  ? null
                  : () => handleSelect(unit.id, unit.unit__logo, unit)
              }
            >
              <div className='info_container_units'>
                <img className='icon' src={unit.unit__logo} />
                <div>{unit.unit__name}</div>
              </div>

              {unit.is_selected ? (
                <img className='action_icon' src={done} />
              ) : (
                <img className='action_icon' src={arrow} />
              )}
            </div>
          ))
        ) : (
          <p>No skins available</p>
        )}
      </div>
      <Footer />

      {show && currentUnit && (
        <div className='modal'>
          <div className='container_glow_modal'>
            <div className='modal-container'>
              <span className='close' onClick={() => setShow(false)}>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='12' cy='12' r='10' fill='#CACACA' fillOpacity='0.46' />
                  <path
                    d='M16 8L8 16'
                    stroke='#131313'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M8 8L16 16'
                    stroke='#131313'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </span>
              <div className='modal_info'>
                <h3>You`ve selected unit {currentUnit.unit__name}<br/> thanks for joining</h3>
                <img src={currentUnit.unit__logo}></img>

                <button className="button-long" onClick={() => navigate("/")}>
                    Back to Main
                </button>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Units;
