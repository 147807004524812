import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./footer.css";
import { useTranslation } from 'react-i18next';
import unitInactive from "./unitInactive.png"
import mine from "./mine.png"
import mineInactive from "./mineInactive.png"
import gaming from "./gaming.png"
import gamingInactive from "./gamingInactive.png"
import invite from "./invite.png"
import inviteInactive from "./inviteInactive.png"
import tap from "./tap.png"
import tapInactive from "./tapInactive.png"
import airdrop from "./airdrop.png"
import airdropInactive from "./airdropInactive.png"
import { useAppData } from '../../AppContext';

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname)
    const {unit} = useAppData()
    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className="menu">
            <div className={`menu-button ${isActive('/units') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/units'); }}>
                {isActive('/units') ? <img src={unit} /> : <img src={unit} />}
                <span className="button-text">Unit</span>
            </div>

            <div className={`menu-button ${isActive('/mine') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/mine'); }}>
                {isActive('/mine') ? <img src={mine} /> : <img src={mineInactive} />}
                <span className="button-text">Mine</span>
            </div>

            <div className={`menu-button ${isActive('/') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/'); }}>
                {isActive('') ? <img src={gaming} /> : <img src={gamingInactive} />}
                <span className="button-text">Gaming</span>
            </div>

            <div className={`menu-button ${isActive('/frens') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/frens'); }}>
                {isActive('/frens') ? <img src={invite} /> : <img src={inviteInactive} />}
                <span className="button-text">Invite</span>
            </div>

            <div className={`menu-button ${isActive('/') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/'); }}>
                {isActive('/') ? <img src={tap} /> : <img src={tapInactive} />}
                <span className="button-text">Tap,Tap</span>
            </div>

            <div className={`menu-button ${isActive('/airdrop') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/airdrop'); }}>
                {isActive('/airdrop') ? <img src={airdrop} /> : <img src={airdropInactive} />}
                <span className="button-text">Airdrop</span>
            </div>
        </div>
    );
}
