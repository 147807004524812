import React, { useState } from 'react';
import "./WalletConnect.scss";
import { updatePlayerWalletAddress } from '../../http';
import { useAppData } from '../../AppContext';
function WalletConnect({title, description, onClick, wallet_address}) {
    const [walletAddress, setWalletAddress] = useState(wallet_address?.length > 0 ? wallet_address : '');   
    const {setNotification} = useAppData()

    const handleAccept = async () => {
        await updatePlayerWalletAddress(walletAddress)
        handleDecline()

    };

    const handleDecline = () => {
        setNotification([])
        onClick()
    };

    return (
        <div className='outer_container_wallet'>
            
               <div className='content_wallet'>
                <div className='title_wallet'>{title}</div>
                <div className='subtext_wallet'>{description}</div>

                <input 
                    type="text" 
                    value={walletAddress} 
                    onChange={(e) => setWalletAddress(e.target.value)} 
                    placeholder="Enter your wallet address"
                    className='input_wallet'
                />

            </div>

            
            <div className="button_container">
                {wallet_address == '' &&
                    <button className='button_thanks' onClick={handleAccept}>
                        Accept
                    </button>
                }
                

                <button className='button_decline' onClick={handleDecline}>
                    Decline
                </button>

                
            </div>
            
            
            
        </div>
    );
}

export default WalletConnect;
