import React, { useState, useEffect } from 'react';
import { useAppData } from '../../AppContext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import Task from './Task';
import DailyReword from './DailyReword';
import Footer from '../Footer/Footer';
import { completeTask, seeTask, getUserTasks, claimDailyRewords } from '../../http';
import coin from "./TaskImg.png";
import dollar_coin from '../../pages/dollar_coin.png';
import calender from './calenderFull.png';
import "./tasks.scss";
import './dailyreword.scss';
import wallet from "./wallet.png"
import { openLink } from '../../Utils/openLink';
export const Tasks = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('tasks');
    const location = useLocation();
    const { setTriggerRender, handleEditCoins, dailys } = useAppData();
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedDaily, setSelectedDaily] = useState(null);
    const [tasks, setTasks] = useState([]);

    const getUserTasksData = async () => {
        const data = await getUserTasks();

        setTasks(data.tasks);
    };

    useEffect(() => {

        getUserTasksData()
        
    }, []);


    useEffect(() => {
        if (location.pathname.includes('/earn/tasks')) {
            setActiveTab('tasks');
        } else if (location.pathname.includes('/earn/income')) {
            setActiveTab('income');
        } else if (location.pathname.includes('/earn/craft')) {
            setActiveTab('craft');
        }
    }, [location]);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    const handleDailyClick = () => {
        setSelectedDaily(true);
    };

    const openTask = async (id,link) => {

        await seeTask(id);
        handleCloseModal();
        getUserTasksData();
        openLink(link)

    }

    const handleMoadalTaskClick = async (link, id, task__coins_reward, status) => {
        try {
            if (status) {
                await completeTask(id);
                handleEditCoins(task__coins_reward);
            } else {
                seeTask(id);
            }
            handleCloseModal();
            getUserTasksData();
        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedTask(null);
        setSelectedDaily(null);
    };

    const forceUpdate = () => setTriggerRender(prev => !prev);

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };

    const handleClaimReword = async () => {
        try {
            await claimDailyRewords();
            handleCloseModal();
            forceUpdate();
        } catch (error) {
            console.error("Failed to claim reword", error);
        }
    }

    const areAllRewardsUnavailable = dailys.every(dayReward => dayReward.status !== 'Available');

    return (
        <div className='container_glow_shop'>
            <Back />
           

            <div className="tab-header tasks">
                <img src={coin} className="tab_img_tasks" draggable="false" alt="Coin" />
                <h2 className="tab-title">Earn more coins</h2>
            </div>

            
            <div className='daily_text first'>Daily tasks</div>
            <div className='shop_grid first'>
                <DailyReword onClick={handleDailyClick} />
            </div>
            <div className="space" />

            <div className='daily_text'>Tasks list</div>

            <div className='shop_grid'>
                {activeTab === 'tasks' && tasks.map((task) => (
                    <Task
                        key={task.id}
                        task={task.task__name}
                        icon={task.task__logo || test}
                        coins={`+${task.task__coins_reward}`}
                        status={task.status}
                        onClick={() => handleTaskClick(task)}
                    />
                ))}
            </div>

            {selectedTask && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46" />
                                <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div className="modal-content_tasks">
                            <img src={selectedTask.task__logo || test} className="modal_image_tasks" alt="Task icon" />
                            <h2 className='modal-title'>{selectedTask.task__name}</h2>
                            {selectedTask.task__link !== "TG" ? (
                                <button className='button_tasks_join' onClick={() => openTask(selectedTask.id,selectedTask.task__link)}>Join</button>
                            ) : (
                                <></>
                            )}
                            <div className='coins_container_tasks'>
                                <img src={dollar_coin} className="coin_icon_modal_tasks" alt="Coin-icon" />
                                +{selectedTask.task__coins_reward}
                            </div>

                            
                            {selectedTask.is_seen && !selectedTask.is_under_timeout ?
                                <button className='open_task button_tasks' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.is_seen)}>Take Reward</button>
                                :
                                selectedTask.is_under_timeout ?  <button className='open_task button_tasks grey'>Wait</button>:<></>
                            }
                        </div>
                    </div>
                </div>
            )}

            {selectedDaily && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46" />
                                <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div className="modal-content_tasks dailyReword">
                            <img className='calender_icon' src={calender} alt="Calendar" />
                            <div className="dailyInfo">
                                <h2>Daily Task</h2>
                                <p className='dailyText'>Accrue coins for logging into the game daily without skipping</p>
                            </div>
                            <div className="rewords">
                                {dailys.map(dayReward => (
                                    <div className={`reword ${dayReward.status}`} key={dayReward.day}>
                                        <h3>Day {dayReward.task__day_number}</h3>
                                        <img src={dollar_coin} alt="coin" />
                                        <p className='rewordValue'>{formatNumber(dayReward.task__coins_reward)}</p>
                                    </div>
                                ))}
                            </div>
                            <button
                                className={`open_task button_tasks ${areAllRewardsUnavailable ? 'disabled' : ''}`}
                                onClick={areAllRewardsUnavailable ? handleCloseModal : handleClaimReword}
                            >
                                {areAllRewardsUnavailable ? 'Come back tomorrow' : 'Claim'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};
