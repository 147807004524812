import React, { useState } from 'react';
import './instruction.scss';
import { useAppData } from '../../AppContext';
import photo1 from "./Images/1.png";
import photo2 from "./Images/2.png";
import photo3 from "./Images/3.png";
import photo4 from "./Images/4.png";
import photo5 from "./Images/5.png";
import photo6 from "./Images/6.png";
import photo7 from "./Images/7.png";

import c1 from "./Images/c1.png";
import c2 from "./Images/c2.png";
import c3 from "./Images/c3.png";
import c4 from "./Images/c4.png";
import c5 from "./Images/c5.png";
import c6 from "./Images/c6.png";

import present from "./Images/present.png"

function Instruction() {

    const {setFirstTime, handleEditCoins} = useAppData();

    const info = [
        {
            id: 1,
            image: photo1,
            title: "Earn coins with every tap!",
            icon: c1,
            subtext: "Use boosters to multiply your coins!"
        },
        {
            id: 2,
            image: photo2,
            title: "Boost your Duck! ",
            icon: c2,
            subtext: "Work your way up from a Recruit to a Hero Duck"
        },
        {
            id: 3,
            image: photo3,
            title: "Perfect your skills.",
            icon: c3,
            subtext: "Maximize the level of your cards for more income."
        },
        {
            id: 4,
            image: photo4,
            title: "Invite friends and receive exclusive bonuses!",
            icon: c4,
            subtext: "Secure valuable coins for you and your friend!"
        },
        {
            id: 5,
            image: photo5,
            title: "Become part of our communities and meet other players!",
            icon: c5,
            subtext: ""
        },
        {
            id: 6,
            image: photo6,
            title: "Use coins and secure an airdrop at the token launch!",
            icon: c6,
            subtext: "Don't forget to invite your friends! Good luck!"
        },
        {
            id: 7,
            image: photo7,
            title: "Click here for a gift.",
            icon: "",
            subtext: "Receive gift"
        }
    ];

    const [currentPage, setCurrentPage] = useState(0);

    const handleNext = () => {
        if (currentPage === info.length - 1) {
            setFirstTime(false);
        } else {
            setCurrentPage(currentPage + 1);
        }
    };
    
    const lastCLick= () =>{
        setFirstTime(false)
        handleEditCoins(5000)
    }   


    return (
        <div className='instruction_container'>
            {currentPage === 6 ? (
                <>
                    <img className='start' src={present} onClick={()=>lastCLick()}/>
                </>
            ) : (
                <>
                    <img className='image_main' src={info[currentPage].image || 'path/to/placeholder.png'} alt={info[currentPage].title} />
                    
                    <div className='bottom_instruction'>
                        <div className='bottom_section'>
                            <div className='left_box'>
                                <img src={info[currentPage].icon} alt='Icon' />
                            </div>
                            <div className='right_box'>
                                <h2>{info[currentPage].title}</h2>
                                <p>{info[currentPage].subtext}</p>
                            </div>
                        </div>
                        <div className='next_button'>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Instruction;
