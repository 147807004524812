import React from 'react';
import './comingsoon.scss';
import img from './img1.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function ComingSoon({ onClick }) {

    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <div className='outer_container_coming'>
            <span className="close" onClick={()=>navigate("/")}>&times;</span>

            <div className='container_glow_coming'>
                <div className='text_coming'>{t('comingSoon')}</div>
                <img src="https://www.battleforcrypto.com/bft_images/comingsoon.png" className='img_coming' alt="coming soon" />
                <div className='glow_coming'></div>
            </div>
        </div>
    );
}

export default ComingSoon;
