import React from 'react';
import "./loading.scss";
import TelegramIcon from "./telegram.png";
import YouTubeIcon from "./youtube.png";
import XIcon from "./21.png";
import Start from "./Start.png";
import bftLogoTab from "./bftLogoTab.png";

function Loading() {
    return (
        <div className="battle-bear-page">
            <div className="bear-image">
                <img src={Start} alt="Battle Bear" />
                <div className='text_container'>
                    <img src={bftLogoTab} alt="BFT Logo" className="bft-logo" />
                    <p>will be launched</p>
                    <h2>on TON</h2>
                    <div className="loader-line"></div>
                </div>
            </div>
            <div className="content">
                <div className="socials">
                    <p>Stay tuned</p>
                    <p>More info in our Socials</p>
                    <div className="icons">
                        <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                            <img src={TelegramIcon} alt="Telegram" />
                        </a>
                        <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                            <img src={XIcon} alt="X" />
                        </a>
                        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                            <img src={YouTubeIcon} alt="YouTube" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
